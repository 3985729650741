import styled from 'styled-components';

export const Container = styled.div`
`;

export const ContainerPesquisa = styled.header`
  border-bottom: 2px solid #199dc4;
  background-color: rgba(0,0,0,.8);
`;

export const AreaPesquisa = styled.header`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CabecalhoPesquisa = styled.div`
  background-color: #F6F7F8;
  border-bottom: 1px solid #dadee2;
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  @media(max-width: 600px) {
    justify-content: center;
    }
`;

export const AreaTitulo = styled.div`
padding: 20px;
`;

export const EstruturaAreaAPIs = styled.div`
  padding: 0px 20px;
  display: grid;
  grid-template-columns: auto;
`;

export const Documentacao = styled.div`
  border-radius: 15px;
  padding: 5px;
  border: 4px #f89933 solid;
`;

export const Filler = styled.div`
  height: 100%;
`;

export const AreaAPIs = styled.div`
    display: grid;
    grid-template-rows: repeat(1fr);
    gap: 30px;
`;
