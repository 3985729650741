import styled from 'styled-components';

export const Container = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 20%;
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 3;
`;
