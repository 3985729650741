import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 25px auto 25px;
  grid-template-columns: 80px auto;
  gap: 3px;
  padding: 5px;
`;

export const AreaTitulo = styled.div`
  border-bottom: 1px solid #ddd;
`;

export const LinkDocumentacao = styled.div`
  grid-row-start: 3;
  grid-column-start: 2;
  grid-row-end: 4;
  text-align: right;
`;

export const Ancora = styled.a`
  text-decoration: none;
  color: #199dc4;
`;
