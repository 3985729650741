import React from 'react';
import IAPI from '../../../models/IAPI';
import TextoAPI from '../../TextoAPI';
import TituloAPI from '../../TituloAPI';
import IconeProjeto from '../IconeProjeto';

import { Container, Ancora, LinkDocumentacao } from './styles';

interface ItemAPIProps {
    api: IAPI;
}

const ItemAPI: React.FC<ItemAPIProps> = ({ api }) => {
    return (
        <Container>
            <IconeProjeto nomeImagem={api.nomeImagem}/>
            <TituloAPI>{api.nome}</TituloAPI>
            <TextoAPI>
                {api.descricaoComercial}
            </TextoAPI>
            <LinkDocumentacao>
                <Ancora href={api.url} target="_blank">Clique aqui para acessar a documentação</Ancora>
            </LinkDocumentacao>
        </Container>
    );
}

export default ItemAPI;