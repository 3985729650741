import React, { useEffect, useState } from 'react';
import IAPI from '../../models/IAPI';
import ServiceAPIs from '../../services/ServiceAPIs';
import InputPesquisa from '../InputPesquisa';
import Titulo from '../Titulo';
import ItemAPI from './ItemAPI';

import { Container, AreaTitulo, AreaAPIs, AreaPesquisa, ContainerPesquisa, EstruturaAreaAPIs, Filler } from './styles';
import LogoRGBsys from '../LogoRGBsys';

const servicoApis = new ServiceAPIs();

const ListaAPIs: React.FC = () => {

    const [pesquisa, setPesquisa] = useState('');
    const [APIs, setAPIs] = useState<IAPI[]>([]);
    const [APIsFiltradas, setAPIsFiltradas] = useState<IAPI[]>([]);


    useEffect(() => {
        const obterApis = async () => {

            if (pesquisa.length > 0) {

                if (pesquisa.length > 2) {
                    const { sucesso, conteudo } = await servicoApis.PesquisarAPIs(pesquisa);
                    if (sucesso) {
                        setAPIs(conteudo);
                    }
                }

                return;
            }

            const { sucesso, conteudo } = await servicoApis.ObterAPIs();
            if (sucesso) {
                setAPIs(conteudo);
            }
        }
        obterApis();
    }, [pesquisa]);

    useEffect(() => {
        setAPIsFiltradas(APIs.filter(a => a.comercial));
    }, [APIs]);

    return (
        <Container>
            <ContainerPesquisa>
                <AreaPesquisa>
                    <LogoRGBsys />
                    <InputPesquisa value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
                </AreaPesquisa>
            </ContainerPesquisa>
            <AreaTitulo>
                <Titulo>Referências das APIS:</Titulo>
            </AreaTitulo>
            <EstruturaAreaAPIs>
                <AreaAPIs>
                    {APIsFiltradas.map(api => <ItemAPI key={api.id} api={api} />)}  
                    <Filler />
                </AreaAPIs>
            </EstruturaAreaAPIs>
        </Container>
    );
}

export default ListaAPIs;