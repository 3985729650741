import React from 'react';

import { Container } from './styles';
import LogoRodape from '../LogoRodape';

const Rodape: React.FC = () => {
    return (
        <Container>
            <LogoRodape />
        </Container>
    );
}

export default Rodape;